<template>
  <div class="game-detail-wrapper">
    <div v-if="gameDetail && gameDetail.gameInfoEntity">
      <!-- <div class="breadcrumb-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">
            {{ gameDetail.gameInfoEntity.Name }} -
            {{ gameDetail.gameInfoEntity.EnglishName }}
          </el-breadcrumb-item>
          <el-breadcrumb-item>详情介绍</el-breadcrumb-item>
        </el-breadcrumb>
      </div> -->

      <div class="content-box">
        <div class="img-box">
          <div class="big-img-box">
            <div class="video-box" v-if="imgIndex != 0 || !gameDetail.gameInfoEntity.MP4">
              <img :src="bigImg" alt />
            </div>
            <div class="video-box" v-else>
              <video id="videoPlay" :src="gameDetail.gameInfoEntity.MP4" controls="controls" width="600px" height="337px"
                :poster="gameDetail.gameInfoEntity.PicList[0]" @pause="pause()" @play="Goplay()" preload="none">
                您的浏览器不支持 video 标签。
              </video>
              <div class="play" v-if="IFplay" @click="play()">
                <img src="@/assets/img/play.png" alt />
              </div>
            </div>
            <span class="collection" @click="collection">
              <i :class="collectionState ? 'el-icon-star-on' : 'el-icon-star-off'
                "></i>
              {{ collectionState ? "已收藏" : "收藏" }}
            </span>
          </div>
          <ul class="gameImgList">
            <li v-for="(item, index) in gameDetail.gameInfoEntity.PicList" :key="index"
              :class="[imgIndex == index ? 'active' : '']" @click="changeBigImg(item, index)">
              <img :src="`${item}?x-oss-process=image/resize,h_65`" alt />
            </li>
          </ul>

          <div class="perfect-archiving">
            <!--  @tab-click="handleClick" -->
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="完美存档" name="1" v-if="PerfectArchives.length > 0">
                <div class="file-box">
                  <div class="item" v-for="(item, index) in PerfectArchives" :key="index">
                    <div class="leftBox">
                      <span class="icon">完美存档</span>
                      <!-- <span class="text ellipsis">{{ item.Describe }}</span> -->
                      <span class="text ellipsis">{{ item.ArchiveName }}</span>
                    </div>
                    <div class="rightBox">
                      <el-button size="small" @click="perfect(item)">查看详情</el-button>
                      <el-button size="small" @click="downLoad(item)">下载存档</el-button>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="我的存档" name="2" v-if="gameDetail &&
                gameDetail.gameInfoEntity &&
                (gameDetail.gameInfoEntity.Type == 2 ||
                  gameDetail.gameInfoEntity.Type == 3)
                ">
                <div class="file-box" v-if="GameArchives.length > 0">
                  <div class="item" v-for="(item, index) in GameArchives" :key="index">
                    <div class="leftBox">
                      <span class="icon">我的存档</span>
                      <span class="text ellipsis">{{ item.Describe }}</span>
                    </div>
                    <div class="rightBox">
                      <!-- {{ item.SaveTime }} -->
                      <el-button size="small" @click="myDownLoad(item)">下载存档</el-button>
                    </div>
                  </div>
                </div>
                <div class="file-box" v-else>
                  <div class="no-file">
                    您还没有保存您自己的游戏存档，赶快去玩游戏吧！
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="推荐游戏" name="3">
                <!-- <h3 class="recommended">相关推荐</h3> -->
                <el-row :gutter="10" class="recommended-box">
                  <el-col :span="6" v-for="(item, index) in recommendList" :key="index" v-if="item.gameInfoEntity">
                    <div class="img-item">
                      <span v-if="item.PerfectArchives.length">支持{{ item.PerfectArchives.length }}个存档</span>
                      <router-link :to="`/gameDetail/${item.ProductID}`" class="btn-experience">
                        <img :src="`${item.gameInfoEntity.PicList[0]}?x-oss-process=image/resize,h_94`" alt />
                        <div class="name">{{ item.gameInfoEntity.Name }}</div>
                      </router-link>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="存档须知" name="4" v-if="(this.gameDetail.gameInfoEntity.Type == 2 ||
                this.gameDetail.gameInfoEntity.Type == 3) &&
                this.gameDetail.gameInfoEntity.GameID !== 'GM000081'
                ">
                <div slot="label" class="tab-pane">
                  存档须知
                  <!-- <span class="isHot">
                    <img src="@/assets/img/hot.gif" alt="" />
                  </span> -->
                </div>
                <div class="file-box">
                  <p>1、注意请先在游戏内正确保存游戏进度</p>
                  <p>
                    2、玩完游戏后，并退出游戏（非steam），特权平台会自动保存您的游戏存档到您的特权账户，方便您下次继续使用
                  </p>
                  <p>
                    3、玩完游戏后，您可以在特权平台游戏详情页面点击【备份存档】按钮，保存您的游戏存档到您的特权账户，方便您下次继续使用
                    <img src="@/assets/img/fileUpload.jpg" width="100%" alt="" />
                  </p>
                  <p>4、保存的存档文件可以在【我的存档】处查看</p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="活动福利" name="5" v-if="adObj1">
                <div slot="label" class="tab-pane">
                  活动福利
                  <span class="isHot">
                    <img src="@/assets/img/hot.gif" alt="" />
                  </span>
                </div>
                <div>
                  <img :src="adObj1.ADImageUrl" width="100%" @click.stop="adToLink(adObj1.ADOpenUrl)" alt="" />
                </div>
              </el-tab-pane>
              <el-tab-pane label="开黑大厅" name="6" v-if="adObj2">
                <div>
                  <img :src="adObj2.ADImageUrl" width="100%" @click.stop="adToLink(adObj2.ADOpenUrl)" alt="" />
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="detail-box">
          <!-- <div v-if="getHighGames.indexOf(gameDetail.ProductID) > -1 ||
            gameDetail.ProductID == 'PC200420000002' ||
            gameDetail.ProductID == 'PC210824000002' ||
            gameDetail.ProductID == 'PC230711000017'
            ">
            <div class="icon-gif" @click="toESports">
              <i class="el-icon-circle-close" @click.stop="mini = true"></i>
              <img v-if="mini" src="@/assets/images/home/icon2.gif" alt="" />
              <img v-else src="@/assets/images/home/icon1.gif" alt="" />
            </div>
          </div>

          <div v-if="getHighGames.indexOf(gameDetail.ProductID) > -1 ||
            gameDetail.ProductID == 'PC240120000001'" @click.stop="$router.push('/hspl')">
            <div class="icon-gif2">
              <img src="@/assets/img/hspl.png" width="100%" alt="" />
            </div>
          </div> -->

          <h3 class="title">
            {{ gameDetail.gameInfoEntity.Name }}
            <span class="cloud-archiving" v-if="gameDetail.gameInfoEntity.Supportedlanguages.includes('中文')">支持中文</span>

            <el-tooltip class="item" effect="dark" content="存档说明：保存专属游戏进度，下次可继续畅玩" placement="top" width="200">
              <span class="cloud-archiving" v-if="gameDetail.gameInfoEntity.Type == 2 ||
                gameDetail.gameInfoEntity.Type == 3
                ">{{
    gameDetail.gameInfoEntity.GameID !== "GM000081"
    ? "支持存档"
    : "游戏设置云存档"
  }}</span>
            </el-tooltip>
          </h3>
          <p class="english-name">
            {{ gameDetail.gameInfoEntity.EnglishName }}
          </p>

          <!-- <div
            class="archive"
            v-if="gameDetail.gameInfoEntity.Type == 2 || gameDetail.gameInfoEntity.Type == 3"
          ></div>-->
          <div class="content">
            <p>{{ gameDetail.gameInfoEntity.Introduction2 }}</p>
          </div>

          <div class="tags-box">
            <div class="tag-item" v-for="(i, index) in gameDetail.gameInfoEntity.Labels.split(',')" :key="index">
              <!-- <el-tag>{{ i }}</el-tag> -->
              {{ i }}
            </div>
          </div>

          <el-row class="price-box">
            <el-col :span="7">
              <!-- <div class="discount">限时折扣</div> -->
            </el-col>
            <el-col :span="10">
              <div class="price">
                <span class="present-price" v-if="gameDetail.DiscountPrice">￥{{ gameDetail.DiscountPrice }}</span>
                <span class="line-price" v-if="gameDetail.OriginalPrice">￥{{ gameDetail.OriginalPrice }}</span>
              </div>
            </el-col>
            <el-col :span="7">
              <div class="take-no" v-if="gameDetail.GameURL">
                <el-button @click="takeNo">立即抢号</el-button>
              </div>
            </el-col>
          </el-row>

          <div class="ps" style="background:#fdf9f8;color: #666;font-style: italic;"
            v-if="gameDetail.ProductID == 'PC240820000001'">
            当前{{ Math.random() < 0.5 ? 0 : 1 }}人在使用此游戏 </div>

              <div class="ps red_cl" v-if="gameDetail.gameInfoEntity.IsWG">
                <i class="el-icon-message-solid"></i>
                提示：禁止浏览外挂网址，下载或使用外挂、损害账号等行为，将永久封禁。
              </div>

              <div v-if="gameDetail.gameInfoEntity.PlatformID == 1">
                <div class="ps red_cl" v-if="gamesStr.length &&
                  gamesStr.indexOf(gameDetail.gameInfoEntity.currentsteamgameid) >
                  -1
                  ">
                  <i class="el-icon-success"></i>
                  已安装：本机已安装此游戏
                </div>

                <div class="ps red_cl" v-else>
                  <i class="el-icon-warning"></i>
                  检测到可能未安装此游戏。
                  <el-button type="primary" :disabled="isDisabled" @click="noticeNetBar">通知安装</el-button>
                </div>
              </div>

              <div class="ps red_cl" v-if="gameDetail.gameInfoEntity.GameID == 'GM000081' ||
                gameDetail.gameInfoEntity.GameID == 'GM001913'
                ">
                <i class="el-icon-message-solid"></i>
                注意：登录后steam账号出现红色信封或黄色信封不影响您的游戏体验
              </div>

              <!-- <span
            class="game-time"
            v-if="
              getHighGames.indexOf(gameDetail.ProductID) > -1 ||
              gameDetail.ProductID == 'PC200420000002'
            "
            >累积游戏时长 <span class="time-num">{{ getTimer }}</span> 小时
            <el-tooltip class="item" effect="dark" placement="right">
              <div slot="content">
                游戏时长近7天累计，累计游戏为“绝地<br />求生-豪华版”的游戏时长
              </div>
              <i
                class="el-icon-question"
                style="font-size: 16px; margin-left: 5px"
              ></i>
            </el-tooltip>
          </span> -->

              <!-- 有加速器的登录游戏按钮 -->
              <!-- <div class="btn-box" v-if="IsShowAccelerator">
            <el-button @click="smasher" class="smasher">
              <img src="./../assets/images/home/ggzuhao.png" width="18px" height="18px" />
              加速
            </el-button>

            <el-button v-if="getStartGameProduct() == gameDetail.ProductID && gameState == 1
              " @click="closeSteam" :class="['start pic-item']" :loading="loading">结束游戏
            </el-button>
            <el-button v-else @click="newStart(gameDetail.gameInfoEntity.Name)" :class="['start pic-item']"
              :disabled="disabled" :loading="loading">{{
                $store.state.gameCountDown === $store.state.initialTime
                ? btnText
                : "游戏启动中...(" + $store.state.gameCountDown + ")"
              }}
            </el-button>
          </div> -->
              <!-- 没有加速器的登录游戏按钮  v-else-->
              <div class="btn-box">
                <div class="flex-btn" v-if="getStartGameProduct() == gameDetail.ProductID && gameState == 1
                  ">
                  <el-button @click="enterThegame" :class="['start pic-item']" :style="{ width: '70%' }">进入游戏
                  </el-button>
                  <el-button @click="changeNumber" :class="['start pic-item']"
                    :style="{ width: '30%', backgroundColor: 'transparent', border: '1px solid #FF3636', color: '#FF3636' }"
                    icon="el-icon-refresh">换号
                  </el-button>
                </div>

                <el-button v-else @click="newStart(gameDetail.gameInfoEntity.Name)" :class="['start pic-item']"
                  :style="{ width: '100%' }" :disabled="disabled" :loading="loading">
                  {{
                    $store.state.gameCountDown === $store.state.initialTime
                    ? btnText
                    : "游戏启动中...(" + $store.state.gameCountDown + ")"
                  }}
                </el-button>
              </div>

              <el-tooltip class="item" effect="dark" content="备份之后，存档文件会保存到特权云端，后续更换电脑也可以使用该存档" placement="top-end"
                width="200">
                <div slot="content">
                  备份之后，存档文件会保存<br />到特权云端，后续更换电脑<br />也可以使用该存档
                </div>
                <el-button class="repair-box bgyl" v-if="(gameDetail.gameInfoEntity.Type == 2 ||
                      gameDetail.gameInfoEntity.Type == 3) &&
                    gameDetail.gameInfoEntity.GameID !== 'GM000081'
                    " @click="repair" :loading="$store.state.backupArchive">备份存档</el-button>
              </el-tooltip>

              <div class="btn-box" v-if="switchBtn && hasWeBuff && !netBarVIP">
                <el-button @click="startWeBuff" :class="[
                  'start_jd',
                  buffDisabled ? 'dis' : '',
                  state !== -1 ? 'startBtn' : '',
                ]" :disabled="buffDisabled">{{
  state == 0
  ? "下载中..."
  : state == 1
    ? "启动中..."
    : "启动修改器"
}}</el-button>
                <!-- :style="{
                background: 'linear-gradient(0deg, #4fbfff 0%, #78a4ff 100%)',
              }" -->
              </div>

              <div class="btn-box" v-if="showBtn">
                <el-button class="buy_btn" @click="buyTo">买游戏账号</el-button>
              </div>

              <div class="collect-cash" v-if="adObj3">
                <img :src="adObj3.ADImageUrl" @click.stop="adToLink(adObj3.ADOpenUrl)" alt="" />
              </div>
          </div>
        </div>
      </div>

      <!-- <div v-else>
      <NoData />
    </div>-->

      <!-- 游戏存档记录 -->
      <el-dialog custom-class="archive-dialog" title="选择存档" :visible.sync="isShow" width="536px" center
        :close-on-click-modal="false" :before-close="closeSteam">
        <div class="last-game">
          <div class="title" v-if="perfectArchive.length">使用游戏的完美存档</div>
          <div class="item">
            <div class="subItem" v-for="(item, index) in perfectArchive" :key="index">
              <el-radio v-model="radio1" :label="'a' + index" border @change="handleChangeRadio(item.ArchiveUrl)"
                class="ellipsis" text-color="#FF3636" fill="#FF3636">
                {{ item.ArchiveName }}</el-radio>
            </div>
          </div>
          <div class="title2" v-if="gameList.length">
            使用我的云存档
            <span>（取游戏最近保存的10条存档记录）</span>
          </div>
          <div class="item">
            <div class="subItem" v-for="(item, index) in gameList" :key="index">
              <el-radio v-model="radio1" :label="index + 1 + ''" border
                @change="handleChangeRadio(item.GameArchiveInfoZipURL)" class="ellipsis" text-color="#FF3636"
                fill="#FF3636">
                {{ item.Describe }}
                <span>{{ item.SaveTime }}</span>
              </el-radio>
              <a class="del" href="javascript:;" @click="del(item)">删除</a>
            </div>
          </div>

          <!-- <div class="btn-box-center" v-if="ifOffline == 0">
          <a href="javascript:;" class="random-account" @click="randomAccount"
            >不取存档, 直接玩游戏</a
          >
          <a href="javascript:;" class="start-game" @click="startArchiveGame"
            >取存档玩游戏</a
          >
        </div>

        <div class="btn-box-center" v-if="ifOffline == 1">
          <a href="javascript:;" class="random-account" @click="offlineGame(0)"
            >不取存档，离线玩游戏</a
          >
          <a href="javascript:;" class="start-game" @click="offlineGame(1)"
            >取存档离线玩游戏</a
          >
        </div> -->
        </div>

        <span slot="footer" class="btn-box-center" v-if="ifOffline == 0">
          <el-button class="random-account" @click="randomAccount">不取存档, 直接玩游戏</el-button>
          <el-button type="primary" class="start-game" @click="startArchiveGame">取存档玩游戏</el-button>
          <el-tooltip class="item" effect="dark" content="注意请先在游戏内正确保存游戏进度。如何存档？请查看详情页面【存档须知】" placement="top-end"
            width="200">
            <div slot="content">
              注意请先在游戏内正确保存<br />游戏进度。如何存档？请查<br />看详情页面【存档须知】
            </div>
            <el-button type="text" class="how">如何存档？</el-button>
          </el-tooltip>
        </span>

        <span slot="footer" class="btn-box-center" v-if="ifOffline == 1">
          <el-button class="random-account" @click="offlineGame(0)">不取存档，离线玩游戏</el-button>
          <el-button type="primary" class="start-game" @click="offlineGame(1)">取存档离线玩游戏</el-button>
          <el-tooltip class="item" effect="dark" content="注意请先在游戏内正确保存游戏进度。如何存档？请查看详情页面【存档须知】" placement="top-end"
            width="200">
            <div slot="content">
              注意请先在游戏内正确保存<br />游戏进度。如何存档？请查<br />看详情页面【存档须知】
            </div>
            <el-button type="text" class="how">如何存档？</el-button>
          </el-tooltip>
        </span>
      </el-dialog>

      <!-- 会员开通提示窗 -->
      <el-dialog title="开通会员" :visible.sync="centerDialogVisible" width="400px" center top="30vh"
        :close-on-click-modal="false">
        <span>您还不是会员,只有成为会员才可以免费玩所有游戏！</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="OpeningMmber">开通会员</el-button>
          <el-button @click="centerDialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>

      <!-- 开通会员弹窗 -->
      <MembersBuy :isShow="isShowMemberDialog" :slectActive="active" @changeShow="showDialogVisible" />

      <!-- 会员开通提示窗 -->
      <el-dialog title="钻石版在线人数超出" :visible.sync="buyMemberDialog" width="400px" center top="30vh"
        :close-on-click-modal="false">
        <span>您可以联系网管增加在线人数，也可以自行开通会员</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="OpeningMmber">开通会员</el-button>
          <el-button @click="buyMemberDialog = false">取 消</el-button>
        </span>
      </el-dialog>

      <!-- 启动其他游戏 -->
      <el-dialog title="温馨提示：" :visible.sync="dialogVisible" width="400px" center :close-on-click-modal="false">
        <span class="text-content">{{ errorStr }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="toHome">{{
            errorStr && errorStr.indexOf("体验人数较多") > -1
            ? "去玩其他游戏"
            : "联系客服解决"
          }}</el-button>
        </span>
      </el-dialog>

      <!-- 游戏时长提示窗 -->
      <!-- <el-dialog title="提示" :visible.sync="gameTimeVisible" width="400px" center top="30vh" :close-on-click-modal="false">
      <span style="line-height: 24px" v-if="gameDetail.gameInfoEntity">您必须达到{{ seniorGame[1] }}小时绝地求生的游戏时长才可以玩【{{
        gameDetail.gameInfoEntity.Name
      }}】；</span>
      <p style="margin-top: 10px; text-align: center; font-size: 16px">
        您当前时长为<span style="color: red"> {{ getTimer }} </span>小时！
      </p>

      <div style="text-align: center; margin-top: 10px">
        <el-button type="primary" @click="toJdqs">先去玩绝地求生</el-button>
      </div>
    </el-dialog> -->

      <!-- 登录游戏进度条 -->
      <el-dialog :visible.sync="progressBarDialog" width="600px" :before-close="progressBarClose" :show-close="false"
        custom-class="progressBarDialog" v-if="gameDetail && gameDetail.gameInfoEntity" :close-on-click-modal="false">
        <div class="ctx">
          <div class="gameName">
            <div>{{ gameDetail.gameInfoEntity.Name }}</div>
            <span v-show="timeShow">按ESC退出启动</span>
          </div>
          <div class="img-box">
            <img class="gameImg" :src="bigImg" :style="{
              filter:
                (gameDetail.gameInfoEntity.Type == 2 ||
                  gameDetail.gameInfoEntity.Type == 3) &&
                  gameDetail.gameInfoEntity.GameID !== 'GM000081'
                  ? 'blur(3px)'
                  : 'none',
            }" width="100%" alt="" />
            <div class="content" v-if="(gameDetail.gameInfoEntity.Type == 2 ||
              gameDetail.gameInfoEntity.Type == 3) &&
              gameDetail.gameInfoEntity.GameID !== 'GM000081'
              ">
              <p class="title">登录注意事项：</p>
              <p>1、登录过程<span>请勿移动鼠标</span></p>
              <p>
                2、登录账号提示<span>＂账号密码错误＂</span>？重新点击＂登录游戏＂可切换账号登录
              </p>
              <p>
                3、登录账号提示<span>＂被封禁＂</span>？重新点击＂登录游戏＂可切换账号登录
              </p>
              <p>4、<span>如何存档</span>？请查看详情页面【存档须知】</p>
            </div>

            <div class="content" v-else>
              <p class="title">登录注意事项：</p>
              <p>1、登录过程<span>请勿移动鼠标</span></p>
              <p>
                2、登录账号提示<span>＂账号密码错误＂</span>？重新点击＂登录游戏＂可切换账号登录
              </p>
              <p>
                3、登录账号提示<span>＂被封禁＂</span>？重新点击＂登录游戏＂可切换账号登录
              </p>
            </div>
          </div>
          <el-progress color="#ff3636" :percentage="progressNum"></el-progress>
          <p>登录steam大概需要2分钟；{{ progressText }}</p>
          <p>登录期间请不要操作鼠标和电脑，否则可能会登录失败</p>
        </div>
      </el-dialog>

      <!-- 绝地高端游戏实名认证 -->
      <!-- <AuthForGame ref="authForGame" /> -->

      <!-- 游戏存档名 -->
      <!-- <el-dialog
      title="保存存档"
      :visible.sync="showGameNameDialog"
      width="470px"
      center
      custom-class="gameNameDialog"
      :show-close="false"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form :inline="true" :model="form">
        <el-form-item label="存档名称：">
          <el-input
            v-model="form.gameName"
            placeholder="存档名称为空，则使用默认名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog> -->

      <!-- 禁用黑名单用户提示弹窗 -->
      <BlackListDialog ref="blacklist" :errorMessage="errorMessage" />

      <!-- 查看存档介绍 -->
      <ViewArchiveDialog ref="viewArchive" :archiveData="archiveData" />

      <!-- 选择上号模式 -->
      <!-- <el-dialog v-if="gameDetail && gameDetail.gameInfoEntity" :title="`请选择启动《${gameDetail.gameInfoEntity.Name}》的模式`"
      :visible.sync="onlineDialogVisible" width="600px" center custom-class="onlineDialogVisible">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="item" @click="onlineStart(1)">
            <h3>在线模式</h3>
            <p>一键启动，随机自动分配账号，游戏免费试用</p>
            <p>注意：网络卡顿时需要使用加速器</p>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="item" @click="onlineStart(2)">
            <h3>离线模式</h3>
            <p>
              一键启动，分配固定账号，并且免费保存每一次存档（主要单机游戏）
            </p>
          </div>
        </el-col>
      </el-row>
    </el-dialog> -->


      <!-- 2为单账号存档列表 -->
      <el-dialog title="选择存档" :visible.sync="dialogTableVisible2" custom-class="dialogTabVisible"
        :close-on-click-modal="false" center width="900px">
        <el-table :data="ArchiveList" height="400px" @row-click="rowClick">
          <el-table-column label="" width="40">
            <template slot-scope="scope">
              <el-radio v-model="radioValue" :label="scope.row.index"
                :disabled="scope.row.state === 0 ? true : false"></el-radio>
            </template>
          </el-table-column>
          <el-table-column property="SteamUse" label="游戏账号" width="120"></el-table-column>
          <el-table-column property="state" label="状态" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.state === 0" style="color: red;">占用</span>
              <span v-if="scope.row.state === 1" style="color: green;">可用</span>
            </template>
          </el-table-column>
          <el-table-column property="ArchiveName" label="存档标题"></el-table-column>
          <el-table-column property="ArchiveTimeNew" label="存档时间" width="160"></el-table-column>
          <el-table-column property="type" label="类型" width="120">
            <template slot-scope="scope">
              {{ scope.row.archiveType === 0 ? '云存档' : '本地存档' }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="操作" width="100" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="text" @click.stop="edit(scope.row)">编辑</el-button>
                <el-button size="mini" type="text" @click.stop="del(scope.row)">删除</el-button>
              </template>
            </el-table-column> -->
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="randomAccount2">不取存档, 直接玩游戏</el-button>
          <el-button type="primary" @click="archiveGame">取存档玩游戏</el-button>

          <!-- <el-tooltip class="item" effect="dark" content="注意请先在游戏内正确保存游戏进度。如何存档？请查看详情页面【存档须知】" placement="top-end"
          width="200">
          <div slot="content">
            注意请先在游戏内正确保存<br />游戏进度。如何存档？请查<br />看详情页面【存档须知】
          </div>
          <el-button type="text" class="how">如何存档？</el-button>
        </el-tooltip> -->
        </span>
      </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  CreateOrder,
  // GetFalseData,
  GetGameInfo,
  GetSimilarGameList,
  GetGameAccountLogin,
  GetGameAccountList,
  AddGameLoginAccount,
  GetNavigationList,
  GetNetbarOnlineNumber,
  GetNetbarInfo,
  GetGameSwitch,
  GetGameArchive,
  GetCurrentGameLogin,
  GetIsWeBuff,
  UserCollection,
  GetIsCollectionGame,
  GetGameListNew,
  GetOfflineAccount,
  DelteGameArchive,
  GetActivity2,
} from "@/api";
// GetConsumerGameTimeByHaoYou,
// import NoData from "./components/NoData.vue";
import MembersBuy from "./components/MembersBuy.vue";
// import AuthForGame from "./components/AuthForGame.vue";
import BlackListDialog from "./components/BListDialog.vue";
import cMethods from "@/util/cMethods.js";
import ViewArchiveDialog from "./components/CViewArchive.vue";

import { exportForCMethods } from "@/util/exportForCMethods.js"

export default {
  name: "GameDetail",
  components: {
    MembersBuy,
    // AuthForGame,
    BlackListDialog,
    ViewArchiveDialog,
  },
  mixins: [exportForCMethods],
  computed: {
    ...mapGetters([
      "userInfo",
      "netBarId",
      "netBarName",
      "netBarVIP",
      "isLogin",
      "getIsAuth",
      "getHighGames",
      "getTimer",
      "gameState",
    ]),
    gameImgList: function () {
      if (this.gameDetail && this.gameDetail.gameInfoEntity) {
        return this.gameDetail.gameInfoEntity.PicList.slice(0, 4);
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      ArchiveAccountObject: null,
      ArchiveObject: null,
      dialogTableVisible2: false,
      ArchiveList: [],
      radioValue: '',
      loading: false,
      // btn_loadingDisabled: false,
      activeName: "3",
      gameTimeVisible: false,
      isShowMemberDialog: false,
      buyMemberDialog: false,
      active: 0,
      gameDetail: {},
      bigImg: "",
      imgIndex: 0,
      IFplay: true, //视频
      // OrderCount: 0, // 体验人数
      disabled: false,
      buffDisabled: false,
      btnText: "登录游戏",
      // btn_disabled: true,
      btn_show: false,
      recommendList: [],
      isShow: false,
      gameList: [],
      perfectArchive: [],
      gameObj: {},
      gameObjData: {},
      Tel: "",
      centerDialogVisible: false,
      adList: [],
      adList2: [],
      onlineNumber: false,
      IsShowAccelerator: false, //是否显示加速器
      gameIndex: 1,
      productId: "",
      dialogVisible: false,
      errorStr: "",
      switchBtn: false,
      hasWeBuff: false,
      showBtn: false,
      buyURL: "",
      radio1: "0",
      ArchiveURL: "", // 存档地址
      timer: null,
      seniorGame: [],
      progressBarDialog: false,
      progressNum: 0,
      progressText: "",
      form: {
        gameName: "",
      },
      errorMessage: "",
      getGameStatus: 2, // 1：安装了  0： 没有安装  2：未知状态
      timeShow: false,
      showTimer: null,
      isDisabled: false, // 通知安装按钮
      state: -1,
      webuffUrl: "",
      GameArchives: [],
      PerfectArchives: [],
      archiveData: null,
      collectionState: false,
      isOnline: "0",
      gamesStr: [],
      OfflineFile: null,
      // onlineDialogVisible: false,
      ifOffline: 0,
      mini: false,
      adObj1: null,
      adObj2: null,
      adObj3: null,
      // showLiveRoomImg: false
      weeklyFreeGame: [],
      ProductID: ""
    };
  },
  beforeDestroy() {
    if (this.showTimer) {
      clearInterval(this.showTimer);
      this.showTimer = null;
    }

    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  created() {
    // 获取游戏列表
    if (this.$route.params.id) {
      this.ProductID = this.$route.params.id;
      this._GetGameInfo(this.$route.params.id);
      // this._GetNavigationList(this.$route.params.id);
    }

    if (localStorage.getItem("gamesStr")) {
      let str = localStorage.getItem("gamesStr");
      str = str.substr(0, str.length - 1);
      this.gamesStr = str.split(",");
    }

    if (sessionStorage.getItem("weeklyFreeGame")) {
      this.weeklyFreeGame = JSON.parse(sessionStorage.getItem("weeklyFreeGame"));
    }
  },
  mounted() {
    if (this.netBarId) {
      this._GetNetbarInfo();
      // 获取修改器开关
      this._getGameSwitch();
    }

    if (sessionStorage.getItem("adList")) {
      let adArr = JSON.parse(sessionStorage.getItem("adList"));
      if (adArr) {
        this.adObj1 = adArr.filter((item) => item.ADName === "活动福利广告")[0];
        this.adObj2 = adArr.filter((item) => item.ADName === "开黑大厅广告")[0];
        this.adObj3 = adArr.filter((item) => item.ADName === "玩游戏领现金")[0];

        adArr.map(item => {
          // console.log(item.ADName, item.Remark)
          if (item.ADName === "配置游戏详情页面【买游戏账号】按钮" && item.Remark === this.ProductID) {
            this.showBtn = true;
            this.buyURL = item.ADOpenUrl;
          }
        })
      }
    }

    // let phone = sessionStorage.getItem("Phone");
    // if (this.netBarId && this.netBarName && phone) {
    //   this._GetFalseData();

    //   // let gameId = this.$route.params.id;
    //   // if (
    //   //   this.getHighGames.indexOf(gameId) > -1 ||
    //   //   gameId == "PC200420000002"
    //   // ) {
    //   //   this._GetConsumerGameTimeByHaoYou(phone);
    //   // }
    // }
    this.$statistic(["A", "游戏详情页", "网页", "访问量"]);

    if (this.netBarVIP) {
      this.$statistic(["A", "钻石版", "游戏详情页", "访问量"]);
    } else {
      // 黄金版广告查询
      // this._GetNavigationList(5);
      // this._GetNavigationList(6);
      this.$statistic(["A", "黄金版", "游戏详情页", "访问量"]);
    }

    // window.vue = this;
  },
  methods: {
    // stopShowLiveRoom() {
    //   if (this.timer) {
    //     clearInterval(this.timer);
    //     this.timer = null;
    //     this.showLiveRoomImg = false;
    //   }
    // },
    // toLive() {
    //   this.$statistic(['B', '绝地详情页', '直播', '点击人数']);
    //   this.$router.push('/LiveRoom');
    // },
    // 直播中倒计时
    // liveRoomRuning() {
    //   let nowTime = new Date().getTime()
    //   console.log(this.startTime, nowTime)
    //   if (this.startTime !== 0 && this.startTime <= nowTime) {
    //     // this.liveRoomStart = true;
    //     // this.liveText = "直播中"
    //     this.showLiveRoomImg = true;
    //   }
    // },
    // 获取游戏列表
    // async _GetActivity2(index) {
    //   console.log("index...", index)
    //   if (this.netBarId) {
    //     let params = {
    //       netbarId: this.netBarId,
    //       pageSize: 3,
    //       pageIndex: 1,
    //       state: index,
    //       userId: (this.userInfo && this.userInfo.ConsumerId) || 0,
    //       gameName: "绝地求生"
    //     };
    //     let res = await GetActivity2(params);
    //     console.log("res", res)
    //     if (res.StatusCode == 200) {
    //       this.PerfectArchives = res.Items.reverse();
    //       if (index === '2' && this.PerfectArchives.length) {
    //         let t = new Date(this.PerfectArchives[0].ActivityStartTime)
    //         this.startTime = t.getTime()
    //         clearTimeout(this.timer);

    //         this.timer = setInterval(() => {
    //           this.liveRoomRuning();
    //         }, 1000);
    //       }
    //     }
    //   } else {
    //     this.$message({
    //       message: "获取网吧ID失败！",
    //       type: "error",
    //     });
    //   }
    // },
    // 获取启动游戏productId
    getStartGameProduct() {
      if (sessionStorage.getItem("runningGame")) {
        let str = JSON.parse(sessionStorage.getItem("runningGame"));
        return str.productId;
      } else {
        return "";
      }
    },
    // 收藏/取消收藏
    async collection() {
      if (this.isLogin && this.userInfo) {
        let params = {
          consumerId: this.userInfo.ConsumerId,
          gameId: this.gameDetail.GameID,
          isCollection: !this.collectionState,
        };
        let res = await UserCollection(params);
        if (res.StatusCode == 200) {
          if (!this.collectionState) {
            this.$message.success(
              "收藏成功，您可以在”我的收藏“列表里面查看所有收藏游戏"
            );
          } else {
            this.$message.success("已取消收藏");
          }
          // this._GetGameInfo(this.gameDetail.ProductID);
          this.collectionState = !this.collectionState;
        } else {
          this.$message.error(res.Message);
        }
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // 获取收藏状态
    async getCollection() {
      let params = {
        consumerId: this.userInfo.ConsumerId,
        gameId: this.gameDetail.GameID,
      };
      let res = await GetIsCollectionGame(params);
      if (res.StatusCode == 200) {
        this.collectionState = res.Object;
      }
    },
    // 获取完美存档
    async _getPerfectArchiving(item, index) {
      if (item.GameID) {
        let gameId = parseInt(item.GameID.substring(2, item.GameID.length));
        let params = {
          consumerId: this.userInfo ? this.userInfo.ConsumerId : 0,
          gameId: gameId,
        };
        let res = await GetGameArchive(params);

        if (res.StatusCode == 200) {
          this.GameArchives = res.Object.GameArchives;
          this.PerfectArchives = res.Object.PerfectArchives;

          if (index == 1) {
            // 有完美存档
            if (this.PerfectArchives.length > 0) {
              this.activeName = "1";
            } else {
              this.activeName = "2";
            }
            // else  if (this.GameArchives.length > 0)  {
            //   this.activeName = "3";
            // }
          }
        } else {
          this.$message({
            message: res.Message,
            type: "error",
          });
        }
      }
    },
    // 我的存档下载
    myDownLoad(row) {
      if (this.isLogin && this.userInfo) {
        this.$confirm("请先关闭游戏和备份当前电脑游戏存档再进行下载！", {
          confirmButtonText: "下载并替换存档",
          distinguishCancelAndClose: true,
        })
          .then(() => {
            cMethods.Form_jsDownSaveForTQ(
              row.GameInfoId,
              row.GameArchiveInfoZipURL
            );
          })
          .catch((action) => { });
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // 查看存档详情
    perfect(item) {
      this.archiveData = item;
      this.$refs["viewArchive"].show();
      // this.$router.push(`/perfectArchiving?gameId=${this.gameDetail.GameID}`);
    },
    // 下载存档
    downLoad(row) {
      if (this.isLogin && this.userInfo) {
        if (this.gameDetail) {
          this.gameId = this.gameDetail.GameID;
          if (
            typeof this.gameId == "string" &&
            this.gameId.indexOf("GM") > -1
          ) {
            this.gameId = parseInt(this.gameId.replace("GM", ""));
          }

          this.$confirm("请先关闭游戏和备份当前电脑游戏存档再进行下载！", {
            confirmButtonText: "下载并替换存档",
            distinguishCancelAndClose: true,
          })
            .then(() => {
              cMethods.Form_jsDownSaveForTQ(this.gameId, row.ArchiveUrl);
            })
            .catch((action) => { });
        }
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event);
      if (tab.name == 2) {
        this._getPerfectArchiving(this.gameDetail, 2);
      }

      // if (this.GameArchives.length) {
      //   // 不支持存档->只显示推荐游戏
      //   this.activeName = "1";
      // } else {
      //   this.activeName = "2";
      // }
    },

    // 选存档
    handleChangeRadio(url) {
      this.ArchiveURL = url;
    },
    // 获取排位号开关
    async _getGameSwitch() {
      let params = {};
      let res = await GetGameSwitch(params);
      if (res.StatusCode == 200) {
        if (res.Object && res.Object.length) {
          res.Object.map((item) => {
            this.switchBtn = JSON.parse(item.Value);
          });
        }
      }
    },
    // 获取当前游戏是否支持修改器
    async _GetIsWeBuff() {
      if (
        this.gameDetail &&
        this.gameDetail.gameInfoEntity &&
        this.gameDetail.gameInfoEntity.currentsteamgameid
      ) {
        let params = {
          steamGameId: this.gameDetail.gameInfoEntity.currentsteamgameid,
        };
        let res = await GetIsWeBuff(params);
        if (res.StatusCode == 200) {
          if (res.Object) {
            this.webuffUrl = res.Object;
            this.hasWeBuff = true;
          }
        }
      }
    },
    // 会员购买弹窗
    showDialogVisible(data, str) {
      if (data === "false") {
        this.isShowMemberDialog = false;
      } else {
        this.isShowMemberDialog = true;
      }
      if (str === "start") {
        if (this.gameDetail && this.gameDetail.gameInfoEntity) {
          this.$confirm(
            `是否确定启动《${this.gameDetail.gameInfoEntity.Name}》游戏？`,
            "提示：",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
            }
          )
            .then(() => {
              // this.start("1");
              this.newStart(this.gameDetail.gameInfoEntity.Name)
            })
            .catch(() => { });
        }
      }
    },
    // 获取网吧信息
    async _GetNetbarInfo() {
      let params = {
        netbarId: this.netBarId,
      };
      let res = await GetNetbarInfo(params);
      if (res.Object) {
        this.IsShowAccelerator = res.Object.NetbarBusiness.IsNotShowAccelerator;
      }
    },
    linkTo(url) {
      let reg =
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/;
      if (!reg.test(url)) {
        this.$router.push(url);
      } else {
        cMethods.OpenIeUrl(url);
      }
    },
    // 获取钻石网吧当前可用名额
    async _GetNetbarOnlineNumber(phone) {
      let params = {
        netbarId: this.netBarId,
        phone: phone,
      };
      let res = await GetNetbarOnlineNumber(params);
      if (res.StatusCode == 200) {
        this.onlineNumber = res.Object;
        if (!this.onlineNumber) {
          this.buyMemberDialog = true;
          return false;
        } else {
          return true;
        }
      }
    },
    // 获取广告游戏
    // async _GetNavigationList(id) {
    //   let params = {
    //     type: 5,
    //   };
    //   let res = await GetNavigationList(params);
    //   console.log("买游戏账号", res)
    //   if (res.StatusCode == 200) {
    //     res.Object.map((item) => {
    //       if (item.Describe == id) {
    //         this.showBtn = true;
    //         this.buyURL = item.Url;
    //       }
    //     });
    //   }
    // },
    // 检测是否有启动游戏
    async _GetCurrentGameLogin() {
      let params = {
        userId: this.userInfo.ConsumerId,
        netbarId: this.netBarId,
      };
      let res = await GetCurrentGameLogin(params);
      if (res.StatusCode == 200) {
        if (res.Object) {
          this.$confirm(
            "您正在玩《" +
            res.Object.GameName +
            "》游戏，确认要切换到《" +
            this.gameDetail.gameInfoEntity.Name +
            "》游戏吗？切换后会中断您正在玩的游戏或下载，以及游戏存档。",
            "提示：",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              closeOnClickModal: false,
            }
          )
            .then(() => {
              this._GetIsOnline();
            })
            .catch(() => { });
        } else {
          this._GetIsOnline();
        }
      } else {
        this.$message({
          message: res.Message,
          type: "error",
        });
      }
    },
    // 获取游戏是否在线
    async rowClick(row, column, event) {
      this.ArchiveObject = row;
      // row.state：0 占用 1：可用
      if (this.ArchiveObject.state !== 0 || (this.ArchiveAccountObject && this.ArchiveAccountObject.GameAccount !== row.SteamUse)) {
        console.log("选中的存档", this.ArchiveObject)
        this.radioValue = row.index;

        if (this.ArchiveAccountObject && this.ArchiveAccountObject.GameAccount !== row.SteamUse) {
          this.ArchiveList.map(item => {
            if (item.state === 1) {
              item.state = null
            }
          })
          this.ArchiveAccountObject = null;
        }

        // 获取机器码
        let Machinecode = cMethods.FormGetMachineCode(); // || "123456"
        if (Machinecode) {
          let params = {
            VipID: this.userInfo ? this.userInfo.ConsumerId : "",
            Tel: this.userInfo ? this.userInfo.Phone : "",
            ProductID: this.gameDetail.ProductID,
            Machinecode: Machinecode,
            netbarId: this.netBarId,
            netbarName: this.netBarName,
            isNotVerifyIDCard: !cMethods.FormIfNetBarSystem(),
            gameAccount: row.SteamUse
          };
          let res = await GetGameAccountLogin(params)
          if (res.StatusCode == 200) {
            let json = JSON.parse(res.Object);
            this.ArchiveAccountObject = json.Data;
            console.log("gameObj...", this.ArchiveAccountObject);


            if (this.ArchiveAccountObject) {
              this.ArchiveList.map(item => {
                if (item.SteamUse == row.SteamUse) {
                  item.state = 1;
                }
              })
            } else {

              this.ArchiveList.map(item => {
                if (item.SteamUse == row.SteamUse) {
                  item.state = 0;
                }
              })
            }
          } else {
            this.$message.error(res.Message);
          }
        }
      }



      // if (this.archiveType === '0') {
      //   console.log('存档...', row);
      //   
      // } else {
      //   let obj = {};
      //   obj.ArchiveLooalPath = row.ArchiveUrl
      //   obj.ArchiveName = row.ArchiveName
      //   obj.ArchiveTime = row.CreateTime
      //   obj.ArchiveTimeNew = row.CreateTime
      //   obj.GameId = row.SteamGameId
      //   obj.Id = row.Id
      //   obj.SteamUse = ''
      //   obj.index = 0
      //   obj.state = 0
      //   obj.type = 0
      //   this.ArchiveObject = obj
      //   console.log('完美存档...', this.ArchiveObject);
      // }

      // // 单账号存档检测账号状态
      // if (this.GameDetail.ArchiveType === 2) {
      //   if (row.state !== 0) {
      //     let params = {
      //       account: row.SteamUse
      //     }
      //     let res = await GetArchiveByGameAccount(params)
      //     if (res.Code == 0) {
      //       this.ArchiveList.map(item => {
      //         if (item.SteamUse == row.SteamUse) {
      //           item.state = res.Data;
      //         }
      //       })
      //     } else {
      //       this.$message.error(res.Message);
      //     }
      //   }
      // }
    },
    // 单账号存档使用存档开始游戏
    archiveGame() {
      if (this.ArchiveObject && this.ArchiveAccountObject) {
        if (this.ArchiveObject.state === 0) {
          this.$message.error('该存档的游戏账号已被占用')
          return
        }
        this.dialogTableVisible2 = false;
        console.log('存档数据', this.ArchiveObject);
        console.log('游戏账号数据', this.ArchiveAccountObject);

        this._NewFormStartGame(this.ArchiveAccountObject, this.ArchiveObject)
      } else {
        this.$message.error('请选择可用存档开始游戏')
      }
    },
    // 排序
    compare(property) {
      return function (a, b) {
        let value1 = a[property];
        let value2 = b[property];
        return value2 - value1;
      }
    },
    // 获取单账号存档
    async _GetNewGameAccountList(index) {
      let gameId = parseInt(
        this.gameDetail.GameID && this.gameDetail.GameID.substring(2, this.gameDetail.GameID.length)
      );
      let params = {
        consumerId: this.userInfo ? this.userInfo.ConsumerId : "",
        gameId: gameId,
      };
      let res = await GetGameArchive(params);
      if (res.StatusCode == 200) {
        let Archives = []; // 云存档
        let localArchive = []; // 本地存档
        let newArr = []; // 合并后的存档
        // 当前用户的本地所有存档
        let str = cMethods.Form_jsGetLocalArchive() || "";
        // 过滤的游戏ID
        let gameID = this.gameDetail.gameInfoEntity && this.gameDetail.gameInfoEntity.steamgameid;
        let userId = this.userInfo && this.userInfo.ConsumerId;
        // 我的存档
        if (
          (res.Object && res.Object.GameArchives && res.Object.GameArchives.length)
        ) {
          // 当前用户最进20条云存档
          let cloudArchives = res.Object.GameArchives.filter(
            (item) => item.Type !== 1
          ).slice(0, 20);

          // 新增状态属性
          cloudArchives.map(item => {
            let obj = {}
            obj.ArchiveLooalPath = item.GameArchiveInfoZipURL
            obj.ArchiveName = item.Describe
            obj.ArchiveTime = item.SaveTime
            obj.ArchiveTimeNew = item.SaveTime
            // obj.GameId = item.GameInfoId
            obj.GameId = gameID
            obj.Id = item.Id
            obj.SteamUse = item.UserGameAccount
            obj.index = index
            obj.state = null
            obj.archiveType = 0
            Archives.push(obj)
          })
          console.log("云存档Archives", Archives);
        }

        if (str && gameID) {
          localArchive = JSON.parse(str).ArchiveData
          localArchive = localArchive.filter(item => (item.GameId == gameID && item.Id == userId)).sort(this.compare("ArchiveTime")).slice(0, 20);
          localArchive.map(item => {
            item.state = ''
            item.type = 1
          })
          console.log("本地存档", localArchive);
        }

        // 合并存档
        newArr = localArchive.concat(Archives)

        if (newArr.length) {
          // 按时间戳排序
          newArr.sort(this.compare("ArchiveTime"))
          // 去重
          console.log('合并后的存档数组: ', newArr);
          // 添加每行下标方便选择
          newArr.map((item, index) => {
            item.index = index;
          })
          // // 返回最终存档列表数据
          // const uniqueArr = newArr.filter((item, index, self) => {
          //   return index === self.findIndex(obj => obj.ArchiveName === item.ArchiveName);
          // });
          // console.log('去重后的存档数组: ', uniqueArr);
          // this.ArchiveList = uniqueArr.slice(0, 20);

          this.ArchiveList = newArr;
        }

        // 检测是否有存档
        if (this.ArchiveList.length) {
          this.dialogTableVisible2 = true;
          // 重置弹窗
          this.radioValue = '';
          this.ArchiveObject = null;
          this.ArchiveAccountObject = null;
        } else {
          // 没存档
          if (index == 1) {
            console.log("在线启动...");
            this._GetGameAccountLogin(1);
            // this._FormStartGame(this.gameObjData);
          }
          if (index == 2) {
            console.log("离线启动...");
            this.offlineGame(0);
          }
        }
      }
    },
    // 获取游戏存档
    async _GetGameAccountList(index) {
      let gameId = parseInt(
        this.gameDetail.GameID.substring(2, this.gameDetail.GameID.length)
      );
      let params = {
        consumerId: this.userInfo ? this.userInfo.ConsumerId : "",
        gameId: gameId,
      };
      let res = await GetGameArchive(params);
      if (res.StatusCode == 200) {
        // 我的存档
        if (
          res.Object &&
          ((res.Object.GameArchives && res.Object.GameArchives.length) ||
            (res.Object.PerfectArchives && res.Object.PerfectArchives.length))
        ) {
          this.isShow = true;
          // 完美存档
          this.perfectArchive = res.Object.PerfectArchives;

          // 我的存档
          this.gameList = res.Object.GameArchives.filter(
            (item) => item.Type !== 1
          ).slice(0, 10);

          if (this.perfectArchive.length) {
            this.radio1 = "a0";
            this.ArchiveURL = this.perfectArchive[0].ArchiveUrl;
          }

          if (this.gameList.length) {
            this.radio1 = "1";
            this.ArchiveURL = this.gameList[0].GameArchiveInfoZipURL;
          }
        } else {
          // 没存档
          if (index == 1) {
            console.log("在线启动...");
            this._FormStartGame(this.gameObjData);
          }
          if (index == 2) {
            console.log("离线启动...");
            this.offlineGame(0);
          }
        }
      } else {
        this.$message({
          message: res.Message,
          type: "error",
        });
      }
    },
    // 选择上号模式
    onlineStart(index) {
      if (this.gameDetail) {
        // 显示当前游戏浮窗
        let obj = {};
        obj.gameName = this.gameDetail.gameInfoEntity.Name;
        obj.gameImg =
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.PicList[0];
        obj.productId = this.gameDetail.ProductID;
        obj.GameID = this.gameDetail.gameInfoEntity.GameID;
        obj.steamgameid = this.gameDetail.gameInfoEntity.steamgameid;
        // 是否支持存档
        if (
          (this.gameDetail.gameInfoEntity.Type == 2 ||
            this.gameDetail.gameInfoEntity.Type == 3) &&
          this.gameDetail.gameInfoEntity.GameID !== "GM000081"
        ) {
          obj.backups = true;
        } else {
          obj.backups = false;
        }
        sessionStorage.setItem("runningGame", JSON.stringify(obj));
        // 30秒后解禁按钮
        this.enable();

        // // 选择上号模式
        // this.onlineDialogVisible = false;

        if (
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.Type == 2
        ) {
          // 单账号存档
          this.ifOffline = 0;
          // 获取新存档方式玩游戏
          this._GetNewGameAccountList(index);
        } else {
          this._GetGameAccountLogin(index);

          // if (index == 1) {
          //   console.log("在线模式");
          //   this._GetGameAccountLogin(index);
          // }
          // if (index == 2) {
          //   console.log("离线模式");
          //   this._GetOfflineAccount(index);
          // }
        }
      }
    },
    // 获取离线账号
    // async _GetOfflineAccount(index) {
    //   if (
    //     this.gameDetail.gameInfoEntity &&
    //     this.gameDetail.gameInfoEntity.Type == 2
    //   ) {
    //     // 单账号存档
    //     this.ifOffline = 1;
    //     // 获取新存档方式玩游戏
    //     this._GetNewGameAccountList(index);
    //   } else {
    //     // 跨账号存档
    //     this.ifOffline = 1;
    //     this._GetGameAccountList(index);
    //   }
    // },
    // 离线玩游戏
    async offlineGame(index) {
      // 获取机器码
      let Machinecode = cMethods.FormGetMachineCode();
      let gameId = "";
      if (this.gameDetail.GameID) {
        gameId = this.gameDetail.GameID.replace("GM", "");
      }

      let params = {
        vipId: this.userInfo && this.userInfo.ConsumerId,
        gameId: this.gameDetail.GameID,
        app: 3,
        machinecode: Machinecode,
        netbarId: this.netBarId,
      };
      let res = await GetOfflineAccount(params);
      if (res.StatusCode == 200) {
        this.OfflineFile = res.Object.OfflineFile;

        if (this.isShow) {
          this.isShow = false;
        }

        if (index == 0) {
          // 不取存档，离线上号
          cMethods.StartSteamOffline(
            this.userInfo.ConsumerId + "",
            this.OfflineFile,
            gameId,
            ""
          );
          this.$statistic([
            "B",
            "离线上号",
            "不取存档，离线玩游戏",
            "按钮点击量",
          ]);
        }

        if (index == 1) {
          // 取存档，离线上号
          cMethods.StartSteamOffline(
            this.userInfo.ConsumerId + "",
            this.OfflineFile,
            gameId,
            this.ArchiveURL
          );
          this.$statistic([
            "B",
            "离线上号",
            "取存档离线玩游戏",
            "按钮点击量",
          ]);
        }
      }
    },
    // 区分游戏上号模式
    _GetIsOnline() {
      if (
        this.isOnline &&
        parseInt(this.isOnline) > 0 &&
        this.gameDetail.gameInfoEntity.Labels.indexOf("支持离线") > -1
      ) {
        if (
          this.gamesStr.length &&
          this.gamesStr.indexOf(
            this.gameDetail.gameInfoEntity.currentsteamgameid
          ) > -1
        ) {
          // 已安装游戏

          // 只支持多人, 取号流程
          this.onlineStart(1);

          // if (
          //   this.gameDetail.gameInfoEntity.Labels.indexOf("支持离线") > -1 &&
          //   this.gameDetail.gameInfoEntity.Labels.indexOf("多人") > -1
          // ) {
          //   // 选择上号模式(支持离线和多人)
          //   this.onlineDialogVisible = true;
          // } else {
          //   // 只支持离线，离线模式取号
          //   if (
          //     this.gameDetail.gameInfoEntity.Labels.indexOf("支持离线") > -1
          //   ) {
          //     this.onlineStart(2);
          //   } else if (
          //     this.gameDetail.gameInfoEntity.Labels.indexOf("多人") > -1
          //   ) {
          //     // 只支持多人, 取号流程
          //     this.onlineStart(1);
          //   } else {
          //     this.onlineStart(1);
          //   }
          // }
        } else {
          // 未安装游戏
          this.$confirm(
            "<p>检测到此游戏未安装<br/>为避免浪费您上网时间<br/>建议您通知网吧工作人员下载该游戏</p>",
            "提示：",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "通知下载，先不上号",
              cancelButtonText: "去上号下载游戏",
              closeOnClickModal: false,
              showClose: false,
              showCancelButton:
                this.gameDetail.gameInfoEntity.Labels.indexOf("多人") > -1
                  ? true
                  : false,
            }
          )
            .then(async () => {
              this.$message({
                message: "已成功发送通知",
                type: "success",
              });

              this.$statistic([
                "B",
                "游戏：" + this.gameDetail.gameInfoEntity.Name,
                "网吧ID：" + this.netBarId,
                "通知网吧安装-点击量",
              ]);

              return false;
            })
            .catch((action) => {
              if (action == "cancel") {
                this.onlineStart(1);
              }
            });
        }
      } else {
        this.onlineStart(1);
      }
    },
    // 获取登录账号
    async _GetGameAccountLogin(index) {
      // 获取机器码
      let Machinecode = cMethods.FormGetMachineCode();
      if (Machinecode) {
        let params = {
          VipID: this.userInfo ? this.userInfo.ConsumerId : "",
          Tel: this.userInfo ? this.userInfo.Phone : "",
          ProductID: this.gameDetail.ProductID,
          Machinecode: Machinecode,
          netbarId: this.netBarId,
          netbarName: this.netBarName,
          isNotVerifyIDCard: !cMethods.FormIfNetBarSystem(),
        };
        let res = await GetGameAccountLogin(params);

        // 禁用黑名单
        if (
          res.data &&
          res.data.response.data.StatusCode == 400 &&
          (res.data.response.data.Message.indexOf("黑名单") > -1 ||
            res.data.response.data.Message.indexOf("禁止体验") > -1)
        ) {
          this.errorMessage = res.data.response.data.Message;
          this.$refs["blacklist"].blackListDialogShow();
          return;
        }
        // 当前IP未授权
        if (
          res.data &&
          res.data.response.data.StatusCode == 400 &&
          (res.data.response.data.Message.indexOf("当前IP未授权") > -1 ||
            res.data.response.data.Message.indexOf("当前IP未授权") > -1)
        ) {
          this.$statistic(["B",
            `IP未授权${this.netBarId}`,
            "点击",
            "展现量",
          ]);
          return;
        }

        // 正常用户
        if (res.StatusCode == 200) {
          this.$store.dispatch("_GetUserInfo");
          let json = JSON.parse(res.Object);

          if (json.Code == 0) {
            this.gameObjData = json.Data;
            // this.gameInfoEntity.Type: 2为单账号存档游戏  3为跨账号存档游戏
            if (
              this.gameDetail.gameInfoEntity &&
              this.gameDetail.gameInfoEntity.Type == 3
            ) {
              // 跨账号存档
              this.ifOffline = 0;
              this._GetGameAccountList(index);
            } else {
              this._FormStartGame(this.gameObjData);
            }
          } else {
            // 当前游戏人数较多，请排队等待！
            this.errorStr = json.Message;
            // 禁用黑名单
            if (
              this.errorStr.indexOf("黑名单") > -1 ||
              this.errorStr.indexOf("禁止体验") > -1
            ) {
              this.errorMessage = this.errorStr;
              this.$refs["blacklist"].blackListDialogShow();
              return;
            } else {
              this.dialogVisible = true;
            }

            // 体验人数较多
            if (this.errorStr.indexOf("体验人数较多") > -1) {
              this.$statistic(["B",
                this.gameDetail &&
                this.gameDetail.gameInfoEntity &&
                this.gameDetail.gameInfoEntity.Name,
                "点击",
                "取号失败",
              ]);
            }

            // 当前IP未授权
            if (this.errorStr.indexOf("当前IP未授权") > -1) {
              this.$statistic(["B",
                `IP未授权${this.netBarId}`,
                "点击",
                "展现量",
              ]);
            }
          }
        } else {
          // 当前IP未授权
          if (res.Message.indexOf("当前IP未授权") > -1) {
            this.$statistic(["B",
              `IP未授权${this.netBarId}`,
              "点击",
              "展现量",
            ]);
          }

          this.$message({
            message: res.Message,
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "获取机器码失败",
          type: "error",
        });
      }
    },
    // 单账号存档上号
    _NewFormStartGame(gameObj, ArchiveObject) {
      if (gameObj) {
        if (ArchiveObject) {
          gameObj.ArchiveURL = ArchiveObject.ArchiveLooalPath;
        } else {
          gameObj.ArchiveURL = "";
        }
        // c++登录游戏
        cMethods.FormStartGameForHaoYou(
          this.userInfo.ConsumerId,
          gameObj.GameAccount,
          gameObj.Gamepwd,
          gameObj.GameName,
          gameObj.GameID,
          gameObj.IsImg,
          gameObj.WGID,
          gameObj.OrderID,
          gameObj.Answer,
          gameObj.Problem,
          gameObj.IsWG,
          gameObj.IsJC,
          gameObj.AccountBancheck,
          gameObj.ArchiveURL,
          gameObj.currentsteamgameid,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.EnglishName,
          gameObj.SteamAccountId,
          gameObj.Explanation,
          gameObj.IsGameSave,
          gameObj.Email,
          gameObj.Emailpwd,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.Type,
          ArchiveObject,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.gameProcessName
        );
      }
    },
    // 上号器上号
    _FormStartGame(gameObj) {
      if (gameObj) {
        if (this.isShow) {
          this.isShow = false;
        }

        if (this.ArchiveURL) {
          gameObj.ArchiveURL = this.ArchiveURL;
        } else {
          gameObj.ArchiveURL = "";
        }
        // c++登录游戏
        cMethods.FormStartGameForHaoYou(
          this.userInfo.ConsumerId,
          gameObj.GameAccount,
          gameObj.Gamepwd,
          gameObj.GameName,
          gameObj.GameID,
          gameObj.IsImg,
          gameObj.WGID,
          gameObj.OrderID,
          gameObj.Answer,
          gameObj.Problem,
          gameObj.IsWG,
          gameObj.IsJC,
          gameObj.AccountBancheck,
          gameObj.ArchiveURL,
          gameObj.currentsteamgameid,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.EnglishName,
          gameObj.SteamAccountId,
          gameObj.Explanation,
          gameObj.IsGameSave,
          gameObj.Email,
          gameObj.Emailpwd,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.Type,
          this.ArchiveObject,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.gameProcessName
        );
      }
    },
    toESports() {
      this.$statistic(["B", "绝地求生详情页面", "赛事悬浮广告", "点击量"]);
      this.$router.push("/eSports");
    },
    // 添加上号
    async _AddGameLoginAccount(item) {
      // 获取机器码
      let Machinecode = cMethods.FormGetMachineCode();
      if (Machinecode) {
        let params = {
          VipID: this.userInfo ? this.userInfo.ConsumerId : "",
          ProductID: item.ProductID,
          AccountID: item.AccountID,
          Machinecode: Machinecode,
          // OrderID: item.OrderID,
          NetbarId: this.netBarId,
          // netbarName: this.netBarName,
        };

        let res = await AddGameLoginAccount(params);
        if (res.StatusCode == 200) {
          let json = JSON.parse(res.Object);
          let gameObj = json.Data;
          if (gameObj) {
            if (this.isShow) {
              this.isShow = false;
            }
            // c++登录游戏
            cMethods.FormStartGameForHaoYou(
              this.userInfo.ConsumerId,
              gameObj.GameAccount,
              gameObj.Gamepwd,
              gameObj.GameName,
              gameObj.GameID,
              gameObj.IsImg,
              gameObj.WGID,
              gameObj.OrderID,
              gameObj.Answer,
              gameObj.Problem,
              gameObj.IsWG,
              gameObj.IsJC,
              gameObj.AccountBancheck,
              this.gameDetail.gameInfoEntity &&
              this.gameDetail.gameInfoEntity.EnglishName,
              gameObj.Explanation,
              gameObj.IsGameSave,
              gameObj.Email,
              gameObj.Emailpwd,
              this.gameDetail.gameInfoEntity &&
              this.gameDetail.gameInfoEntity.Type,
              this.ArchiveObject,
              this.gameDetail.gameInfoEntity &&
              this.gameDetail.gameInfoEntity.gameProcessName
            );
          }
        } else {
          this.$message({
            message: res.Message,
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "获取机器码失败",
          type: "error",
        });
      }
    },
    // 存档登录游戏
    startGameDialog() {
      if (this.gameObj) {
        this._AddGameLoginAccount(this.gameObj);
      }
    },
    // 开始存档游戏
    startArchiveGame() {
      if (this.gameObjData) {
        this._FormStartGame(this.gameObjData);
        this.$statistic(["B", "取存档玩游戏", "按钮", "点击量"]);
      }
    },
    // 直接上号
    randomAccount() {
      if (this.gameObjData) {
        this.ArchiveURL = "";
        this.ArchiveObject = null;
        this._FormStartGame(this.gameObjData);
      }
      if (this.dialogTableVisible2) {
        this.dialogTableVisible2 = false;
      }
    },
    // 单账号不取存档直接上号
    randomAccount2() {
      this.dialogTableVisible2 = false;
      this._GetGameAccountLogin(1);
    },
    // 修复存档改为备份存档
    repair() {
      if (this.isLogin && this.userInfo) {
        cMethods.Form_jsUploadArchiveForTQ(
          this.userInfo.ConsumerId,
          this.gameDetail.GameID,
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.currentsteamgameid
        );
      } else {
        eventBus.$emit("getQrCode");
      }
    },
    // 获取游戏详情
    async _GetGameInfo(id) {
      let params = {
        gid: id,
      };
      let res = await GetGameInfo(params);
      if (res.StatusCode == 200) {
        let data = JSON.parse(res.Object);
        this.gameDetail = data.Data;
        console.log("详情data...", data);

        // 调试用...
        // this._GetNewGameAccountList(1)
        this.isOnline = res.Message;
        // console.log("this.isOnline", this.isOnline);

        // 获取赛事列表
        // if (this.gameDetail.ProductID == 'PC200420000002') {
        //   this._GetActivity2('2');
        // }

        // 支持存档->查询存档
        if (
          this.gameDetail.gameInfoEntity.Type == 2 ||
          this.gameDetail.gameInfoEntity.Type == 3
        ) {
          this._getPerfectArchiving(this.gameDetail, 1);
        }
        // else {
        //   // 不支持存档->只显示推荐游戏
        //   this.activeName = "3";
        // }

        this.productId = this.gameDetail.ProductID;
        this.bigImg = this.gameDetail.gameInfoEntity.PicList[0];

        if (this.gameDetail && this.gameDetail.gameInfoEntity.IsRepair) {
          this.disabled = true;
          this.loading = true;
          this.btnText = "游戏维护中";
        } else if (
          this.$store.state.gameCountDown !== this.$store.state.initialTime
        ) {
          // this.enable();
          this.loading = true;
          // else if (sessionStorage.getItem("btnDisabled") == "1") {
          // this.disabled = true;
          // if (this.timerGlob < 30 && this.timerGlob > 0) {
          //   this.btnText = "游戏启动中...（" + this.timerGlob + "）";
          // } else {
          //   this.disabled = false;
          //   this.btnText = "登录游戏";
          // }
        } else {
          // this.disabled = false;
          this.loading = false;
          this.btnText = "登录游戏";
        }

        if (this.gameDetail && this.gameDetail.gameInfoEntity.Labels) {
          // 获取相关推荐
          // this._GetSimilarGameList(this.gameDetail.gameInfoEntity.Labels);
          this._GetGameList();
        }
        // 游戏目录名称
        // if (
        //   this.gameDetail &&
        //   this.gameDetail.gameInfoEntity &&
        //   this.gameDetail.gameInfoEntity.CatalogueName
        // ) {
        cMethods.FormJsGetUserGameName(
          this.gameDetail.gameInfoEntity.CatalogueName || "",
          this.gameDetail.gameInfoEntity.currentsteamgameid || ""
        );
        this.getGameStatus = cMethods.FormGetFindGameResult();
        // }

        // 获取游戏收藏状态
        if (this.isLogin && this.userInfo) {
          this.getCollection();
        }

        // 获取当前游戏是否有修改器
        this._GetIsWeBuff();

        // 网吧：绝地求生未安装统计
        if (this.gamesStr.length &&
          this.gamesStr.indexOf(
            this.gameDetail.gameInfoEntity.currentsteamgameid
          ) > -1) {
        } else {
          if (this.gameDetail.ProductID == 'PC200420000002') {
            this.$statistic([
              "D",
              "游戏：绝地求生",
              "网吧ID：" + this.netBarId,
              "未安装-展现量",
            ]);
          }
        }
      }
    },
    // 修改详情页登录游戏按钮状态
    changeBtnText(str, b) {
      this.loading = b;
      this.btnText = str;
    },
    // 获取相关推荐
    async _GetSimilarGameList(labels) {
      let params = {
        labels: labels,
        sidx: "newID()",
      };
      let res = await GetSimilarGameList(params);
      if (res.StatusCode == 200) {
        let data = JSON.parse(res.Object);
        this.recommendList = data.Data;
      }
    },
    // 获取游戏列表
    async _GetGameList() {
      let params = {
        source: 3,
        keyword: "",
        pageSize: 20,
        pageIndex: 1,
        labels: "",
        platformId: "",
        isArchive: true,
      };

      let res = await GetGameListNew(params);
      if (res.StatusCode == 200) {
        // this.recommendList = res.Items;
        // console.log("this.recommendList", this.recommendList);

        // let result = data.Data;

        //定义一个数组，保存放入的数
        let arr = [];
        //随机产生1-33之间的一个数（避免首末概率不均，采用max+1，向下取整）
        function randomInteger(min, max) {
          let random = min + Math.random() * (max + 1 - min);
          return Math.floor(random);
        }

        //判断够不够6个数
        do {
          for (let i = 0; i < 8 - arr.length; i++) {
            let random = randomInteger(0, 19);
            if (arr.indexOf(random, 0) == -1) {
              arr.push(random);
            } else {
              break;
            }
          }
        } while (arr.length != 8);

        if (res.Items.length > 0) {
          let reArr = [];
          res.Items.map((item, index) => {
            if (arr.indexOf(index) > -1) {
              reArr.push(item);
            }
          });
          this.recommendList = reArr;
        }
      }
    },
    //获取平台、网吧用户和游戏数据
    // async _GetFalseData() {
    //   let params = {
    //     netbarId: this.netBarId,
    //   };
    //   let res = await GetFalseData(params);
    //   if (res.StatusCode == 200) {
    //     this.GameSort = res.Object.GameSort;
    //     this.GameSort.length &&
    //       this.GameSort.map((i) => {
    //         if (this.gameDetail) {
    //           if (i.GameName == this.gameDetail.GameName) {
    //             this.OrderCount = i.OrderCount;
    //           }
    //         }
    //       });
    //   }
    // },
    // 登录成功弹窗跳转
    adToLink(url) {
      this.$router.push(url);
    },
    // 获取在线游戏时长
    // async _GetConsumerGameTimeByHaoYou(phone) {
    //   let params = {
    //     phone: phone,
    //   };
    //   let res = await GetConsumerGameTimeByHaoYou(params);
    //   if (res.StatusCode == 200) {
    //     this.timer = parseFloat(res.Object / 60).toFixed(1);
    //   }
    // },
    // 删除
    del(row) {
      this.$confirm("是否确定要删除该存档信息吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let params = {
            archiveId: row.Id,
          };
          let res = await DelteGameArchive(params);
          if (res.StatusCode == 200) {
            this.gameList.splice(
              this.gameList.findIndex((item) => item.Id == row.Id),
              1
            );

            this.$message({
              message: "删除存档成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.Message,
              type: "error",
            });
          }
        })
        .catch((action) => {
          // if (action == "cancel") {
          //   cMethods.JsDownFileAndOpenDir(row.GameArchiveInfoZipURL);
          // }
        });
    },
    // 立即抢号
    takeNo() {
      if (this.gameDetail && this.gameDetail.GameURL) {
        cMethods.OpenIeUrl(this.gameDetail.GameURL);
      }
    },
    // 跳转淘号帮广告
    linkToTHB() {
      cMethods.OpenIeUrl(
        "http://www.taohaobang.com/steam/M0000002019.html?from=580my"
      );
    },
    // 切换大图
    changeBigImg(src, index) {
      this.bigImg = src;
      this.imgIndex = index;
    },
    // 30秒后解除按钮
    enable() {
      if (this.$store.state.gameCountDown === this.$store.state.initialTime) {
        this.loading = true;
        this.$store.dispatch("getGameCountDown");
      }
    },
    // 启动修改器
    startWeBuff() {
      if (this.webuffUrl) {
        if (
          this.gameDetail &&
          this.gameDetail.gameInfoEntity &&
          this.gameDetail.gameInfoEntity.currentsteamgameid
        ) {
          this.buffDisabled = true;

          this.state = cMethods.FormNetBarWeBuff(
            this.gameDetail.gameInfoEntity.currentsteamgameid,
            this.webuffUrl
          );

          this.$statistic(["B", "启动修改器", "按钮", "点击量"]);
        }
      }
    },
    // 关闭steam
    closeSteam() {
      this.isShow = false;
      this.$store.commit("setGameState", false);
      sessionStorage.removeItem("runningGame");
      setTimeout(() => {
        cMethods.JsExtern_Form_CloseSteam();
      }, 3000);
    },
    // 换号
    changeNumber() {
      this.$confirm(`您正在玩《${this.gameDetail.gameInfoEntity.Name}》游戏，切换后会中断您正在玩的游戏或下载，以及游戏存档！`, "切换游戏提示", {
        confirmButtonText: "确认切换",
        distinguishCancelAndClose: true,
      })
        .then(() => {
          this.isShow = false;
          this.$store.commit("setGameState", false);
          sessionStorage.removeItem("runningGame");
          setTimeout(() => {
            cMethods.JsExtern_Form_CloseSteam();
          }, 3000);
        })
        .catch((action) => { });


      // this.$store.commit("setGameCountDown", 30);
      this.$statistic(["B", "【换号】", "按钮", "点击量"]);
    },
    // 进入游戏
    enterThegame() {
      cMethods.Form_jsShowSteam();
      this.$statistic(["B", "【进入游戏】", "按钮", "点击量"]);
    },
    // 新上号流程
    newStart(gameName) {
      // ***用户身份验证***
      this.userAuth(() => {
        // ***上号***
        let result = cMethods.Form_GetShanghaoState(gameName);
        if (result) {
          this.$message.error("短时间内多次上号，暂停取号");
          return false;
        } else {
          this._GetCurrentGameLogin();
        }
      });

      // 百度统计
      if (this.netBarVIP) {
        this.$statistic(["B", "游戏详情页", "钻石登录游戏", "点击量"]);
      } else {
        this.$statistic(["B", "游戏详情页", "黄金登录游戏", "点击量"]);
      }
    },
    // 用户身份验证
    async userAuth(cb) {
      if (!this.isLogin && !this.userInfo) {
        eventBus.$emit("getQrCode");
        return false;
      }
      let phone = sessionStorage.getItem("Phone");
      if (!phone) {
        eventBus.$emit("showLoginPhone");
        return false;
      }

      // 需实名认证
      if (
        this.getIsAuth &&
        !this.userInfo.IDCard &&
        !cMethods.FormIfNetBarSystem()
      ) {
        // 三要素认证
        // if (
        // this.userInfo.RealNameType !== 2
        //   this.getHighGames.indexOf(this.gameDetail.ProductID) > -1 || this.getHighGames.indexOf("全部") > -1
        // ) {
        // }
        eventBus.$emit("showAuth");
        return false;
      }

      if (!this.userInfo.IsNotMember) {
        if (this.netBarVIP == true) {
          let res = this._GetNetbarOnlineNumber(phone);
          if (!res) return false;
        } else {
          if (this.getIsAuth && !this.userInfo.IDCard) {
            eventBus.$emit("showAuth");
            return false;
          }
          // console.log("周免游戏：", this.weeklyFreeGame)
          // console.log("当前商品ID：" + this.gameDetail.ProductID)
          if (!this.weeklyFreeGame.includes(this.gameDetail.ProductID)) {
            this.isShowMemberDialog = true;
            return false;
          }
        }
      }
      cb && cb();
    },
    //视频播放
    play() {
      var vdo = document.getElementById("videoPlay");
      vdo.play();
      this.IFplay = false;
    },
    //视频暂停
    pause() {
      this.IFplay = true;
    },
    //视频播放
    Goplay() {
      this.IFplay = false;
      this.$statistic(["B", "游戏详情页", "视频播放", "点击量"]);
    },
    // 选择存档游戏
    handleChange(index, obj) {
      this.active = index;
      this.gameObj = obj;
    },
    // 开通会员弹窗提示
    OpeningMmber() {
      // this.$router.push("/members");
      this.buyMemberDialog = false;
      this.centerDialogVisible = false;
      this.isShowMemberDialog = true;
      this.$statistic([
        "D",
        "游戏详情页",
        "网吧ID：" + this.netBarId,
        "钻石版在线人数超出",
      ]);
    },
    // 体验其他游戏
    toHome() {
      this.dialogVisible = false;
      this.$router.push("/home");
    },
    // 加速器
    smasher() {
      cMethods.ExternFormBoost();
    },
    // 购买游戏账号
    buyTo() {
      if (this.buyURL) {
        this.$router.push({ path: "/link", query: { url: this.buyURL } });
        this.$statistic(["B", "游戏详情页面", "点击", "买游戏账号"]);
      }
    },
    // 跳转绝地游戏
    toJdqs() {
      this.$router.push("/gameDetail/PC200420000002");
    },

    noticeNetBar() {
      if (this.gameDetail && this.gameDetail.gameInfoEntity) {
        this.isDisabled = true;
        this.$message({
          message: "已成功发送通知",
          type: "success",
        });

        setTimeout(() => {
          this.isDisabled = false;
        }, 3000);

        this.$statistic([
          "B",
          "游戏：" + this.gameDetail.gameInfoEntity.Name,
          "网吧ID：" + this.netBarId,
          "通知网吧安装-点击量",
        ]);
      }
    },
  },
  watch: {
    "$store.state.gameCountDown"(oldVal, newVal) {
      if (oldVal === this.$store.state.initialTime) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.game-detail-wrapper {
  width: 1040px;
  height: 676px;
  margin: 0 auto;
  padding: 20px;

  .breadcrumb-box {
    position: relative;

    .el-breadcrumb {
      margin-bottom: 10px;
      line-height: 26px;
    }

    ::v-deep .el-breadcrumb__inner.is-link {
      font-size: 16px;
      font-weight: normal;
      @include font_col3();
    }
  }

  .content-box {
    width: 1040px;
    height: 676px;

    .img-box {
      width: 600px;
      // height: 610px;
      float: left;

      .big-img-box {
        position: relative;

        .collection {
          padding: 5px;
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          right: 0;
          bottom: 0;
          color: #fff;
          cursor: pointer;
        }
      }

      .video-box {
        height: 337px;
        margin-bottom: 8px;
        background: #18212a;
        position: relative;
        border-radius: 6px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        .play {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -28px;
          margin-left: -28px;
          cursor: pointer;

          img {
            width: 56px;
            height: 56px;
            transition: all 550ms;
          }
        }
      }

      .gameImgList {
        li {
          width: 116px;
          display: inline-block;
          margin-right: 5px;
          position: relative;
          box-sizing: border-box;

          .img-item.active:after {
            border-width: 10px;
            border-bottom-color: $white-col;
            top: -20px;
          }

          .img-item.active:after {
            border: solid transparent;
            content: " ";
            height: 0;
            top: 0;
            left: 50%;
            position: absolute;
            width: 0;
            margin-left: -10px;
          }

          img {
            width: 100%;
            height: 65px;
            border-radius: 4px;
          }
        }

        li:last-child {
          margin-right: 0;
        }

        li.active {
          border-radius: 6px;
          border: 2px solid $red-col;
        }

        li.active:after {
          border: solid transparent;
          content: " ";
          height: 0;
          top: 0;
          left: 50%;
          position: absolute;
          width: 0;
          margin-left: -10px;
          border-width: 10px;
          border-bottom-color: $red-col;
          top: -20px;
        }
      }

      .perfect-archiving {
        .tab-pane {
          position: relative;

          .isHot {
            position: absolute;
            top: -10px;
            left: 10;
          }
        }

        .file-box {
          height: 192px;
          overflow: auto;
          line-height: 28px;

          .no-file {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            @include font_col3();
          }
        }

        .file-box {
          padding: 10px;
          @include item_bg_col();
          border-radius: 4px;

          .item {
            padding: 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(155, 155, 156, 0.3);

            .leftBox {
              display: flex;
              justify-content: center;
              align-items: center;

              .icon {
                width: 80px;
                height: 30px;
                line-height: 30px;
                display: inline-block;
                text-align: center;
                margin-right: 10px;
                color: #fff;
                border-radius: 4px;
                // background: linear-gradient(90deg, #01a9b0 0%, #91e4e6 100%);
                background: linear-gradient(90deg, #ff3636 0%, #ff765a 100%);
              }

              .text {
                width: 300px;
                display: inline-block;
                font-size: 16px;
                @include font_col6();
              }
            }

            .rightBox {
              width: 180px;
              text-align: right;

              .el-button {
                color: #ff2828;
                border-color: #ff2828;
                background: transparent;

                &:hover {
                  background: #ff2828;
                  color: #fff;
                }

                &:focus {
                  color: #ff3636;
                  border-color: #ff3636;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }

      .recommended {
        margin: 6px 0;
        line-height: 28px;
        font-size: 16px;
        @include font_col3();
      }

      .recommended-box {
        .img-item {
          margin-bottom: 16px;
          cursor: pointer;
          border-radius: 4px;
          overflow: hidden;
          position: relative;

          span {
            padding: 5px 10px;
            position: absolute;
            top: 0;
            right: 0;
            color: #fff;
            font-size: 12px;
            // background: linear-gradient(90deg, #689dff 0%, #68afff 100%);
            background: linear-gradient(90deg, #ff3636 0%, #ff765a 100%);
          }

          img {
            width: 100%;
            height: 94px;
          }

          .name {
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            padding: 6px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            box-sizing: border-box;
            // @include font_col6();
            text-align: center;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
            // @include item_bg_col();
          }

          &:hover {
            // transform: translateY(-5px);
            box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }

    .detail-box {
      width: 380px;
      height: 636px;
      padding: 20px;
      float: right;
      border-radius: 6px;
      position: relative;
      @include item_bg_col();

      .icon-gif {
        position: absolute;
        top: 220px;
        right: 20px;
        cursor: pointer;

        i {
          position: absolute;
          top: 5px;
          right: 5px;
          color: #fff;
          font-size: 24px;
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .icon-gif2 {
        position: absolute;
        top: 160px;
        right: 20px;
        cursor: pointer;

        i {
          position: absolute;
          top: 5px;
          right: 5px;
          color: #fff;
          font-size: 24px;
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .game-time {
        // margin-left: 140px;
        margin-top: 10px;
        display: block;
        font-size: 16px;
        @include font_col6();

        .time-num {
          font-weight: bold;
          font-size: 20px;
          color: $red_col;
        }
      }

      .archive {
        width: 124px;
        height: 27px;
        position: absolute;
        top: -8px;
        right: 0;
        background: url("./../assets/images/games/archive.png");
      }

      .title {
        line-height: 30px;
        font-size: 20px;
        margin-bottom: 10px;
        // display: flex;
        // align-items: center;
        @include font_col3();

        .cloud-archiving {
          width: 60px;
          height: 14px;
          line-height: 14px;
          text-align: center;
          padding: 5px;
          margin-left: 10px;
          background: $linear-col;
          color: $white-col;
          border-radius: 4px;
          font-size: 12px;
          display: inline-block;
        }
      }

      .english-name {
        margin-bottom: 15px;
      }

      .content {
        line-height: 28px;
        height: 170px;
        margin-bottom: 10px;
        @include font_col6();

        p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 6;
          overflow: hidden;
        }
      }

      .tags-box {
        // display: flex;
        // margin-bottom: 15px;
        // height: 80px;
        overflow: hidden;

        .tag-item {
          padding: 8px 10px;
          display: inline-block;
          // width: 80px;
          // height: 32px;
          border: none;
          // text-align: center;
          margin: 0 10px 10px 0;
          border-radius: 4px;
          @include font_col6();
          @include tag_bg_col();
          // padding: 0;

          // .el-tag {
          //   width: 80px;
          //   height: 32px;
          //   border: none;
          //   text-align: center;
          //   margin: 10px;
          //   border-radius: 4px;
          //   @include font_col6();
          //   @include tag_bg_col();
          //   padding: 0;
          // }
        }
      }

      .price-box {
        margin-bottom: 15px;

        .discount {
          width: 68px;
          height: 24px;
          line-height: 24px;
          background: #ff6e16;
          color: $white-col;
          text-align: center;
        }

        .present-price {
          color: #ff6e16;
          font-size: 18px;
          line-height: 24px;
        }

        .line-price {
          text-decoration: line-through;
          font-size: 16px;
          line-height: 24px;
          @include font_col9();
        }

        .take-no {
          text-align: center;

          .el-button {
            width: 76px;
            height: 24px;
            line-height: 24px;
            border: 1px solid #ff6e16;
            color: #ff6e16;
            border-radius: 0;
            padding: 0;
            background: $white-col;
          }
        }
      }

      .repair-box {
        width: 100%;
        height: 46px;
        text-align: center;
        font-size: 16px;
        background: #ccc;
        color: $white-col;
        border: none;
        margin-top: 10px;
      }

      .bgyl {
        background: #ff9268;
      }

      .ps {
        line-height: 20px;
        padding: 10px 10px;
        margin-top: 10px;
        @include ps_bg_col();

        // text-align: center;
        .el-button {
          padding: 5px;
          font-size: 12px;
          border-color: #e13c3c !important;
          background: transparent !important;
          color: #e13c3c;
        }

        .el-button--primary.is-disabled {
          border-color: #ccc !important;
          background: #ccc !important;
          color: #fff;
        }
      }

      .ps.red_cl {
        @include ps_col();
      }

      .num-box {
        height: 40px;
        line-height: 40px;
        margin-bottom: 15px;
        background: #e13c3c;
        color: $white-col;
        text-align: center;
        font-size: 16px;

        span {
          font-size: 24px;
          color: #f20202;
          vertical-align: bottom;
        }
      }

      .btn-box {
        margin-top: 10px;

        .flex-btn {
          display: flex;
        }

        .smasher {
          width: 108px;
          height: 50px;
          color: $white-col;
          font-size: 20px;
          border-radius: 0;
          border: none;
          border-radius: 4px;
          display: inline-block;
          background: $red-col;
        }

        .start {
          width: 262px;
          height: 50px;
          color: $white-col;
          font-size: 20px;
          border-radius: 0;
          border: none;
          border-radius: 4px;
          display: inline-block;
          background: $red-col;
        }

        .start_jd {
          width: 100%;
          height: 50px;
          font-size: 20px;
          color: $white-col;
          // background: linear-gradient(0deg, #4fbfff 0%, #78a4ff 100%);
          background: #ff6c68;
          border: none;

          &.startBtn {
            background: url("../assets/img/buff.gif") no-repeat !important;
            background-size: 100% 100%;
          }
        }

        .dis {
          background: #ccc;
        }

        .buy_btn {
          width: 100%;
          height: 50px;
          font-size: 20px;
          color: $white-col;
          background: #732ddc;
          border: none;
        }

        &:hover {
          // transform: translateY(-5px);
          // box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);
        }
      }

      .collect-cash {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px;

        img {
          width: 100%;
          display: block;
          cursor: pointer;
        }
      }
    }
  }

  ::v-deep .el-breadcrumb__separator[class*="icon"] {
    @include font_col3();
  }

  ::v-deep .el-dialog__body {
    .text-content {
      font-size: 18px;
      @include font_col3();
    }
  }

  .archive-dialog {
    .last-game {
      // padding-bottom: 40px;
      max-height: 430px;
      overflow: auto;

      .title {
        height: 46px;
        line-height: 46px;
        padding-left: 20px;
        // background: #43406b;
        background: #ff3636;
        color: $white-col;
        font-size: 18px;
      }

      .title2 {
        height: 46px;
        line-height: 46px;
        padding-left: 20px;
        // background: #ecf3f7;
        // color: #0096ff;
        background: #ff3636;
        color: $white-col;
        font-size: 18px;

        span {
          font-size: 14px;
          // @include font_col3();
        }
      }

      .item {
        padding: 0 20px;

        .subItem {
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          position: relative;

          // background: #ffe9e9;
          // border-radius: 6px;
          // color: #FF3636;
          // @include tag_item_bg_col();
          .ellipsis {
            width: 400px;
          }

          span {
            position: absolute;
            top: 0;
            right: 0;
            line-height: 40px;
            margin-right: 40px;
            @include font_col9();
          }

          .el-radio.is-bordered {
            width: 486px;
            flex: 1;
            border: none;
            border-radius: 0 !important;
          }

          .el-radio.is-bordered.is-checked {
            border: 1px solid #ff3636;
          }

          ::v-deep .el-radio__label {
            color: #a4a5ba;
          }

          ::v-deep .is-checked .el-radio__label {
            color: #ff3636 !important;

            span {
              color: #ff3636 !important;
            }
          }

          ::v-deep .is-checked .el-radio__inner {
            border-color: #ff3636 !important;
            background: #ff3636 !important;
          }
        }

        .del {
          margin-top: 15px;
          margin-right: 10px;
          text-decoration: underline;
          font-size: 12px;
          // @include font_col6();
          color: #999;
        }
      }
    }

    .btn-box-center {
      @include item_bg_col();
      text-align: center;
      display: block;
      text-align: right;

      .el-dialog__footer {
        padding: 10px 10px 20px;
      }

      .how {
        margin-left: 10px;
        color: #ff3636;
      }

      .how:active {
        border-color: transparent !important;
      }

      .start-game {
        width: 181px;
        height: 44px;
        // line-height: 44px;
        display: inline-block;
        box-sizing: border-box;
        color: #ff3636;
        border: 1px solid #ff3636;
        background: transparent;
        color: #fff;
        background: #ff3636;
      }

      .random-account {
        width: 181px;
        height: 44px;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
        color: #ff3636;
        border: 1px solid #ff3636;
        color: #fff;
        background: #ff3636;
      }
    }

    .el-button {
      border-color: transparent;
    }
  }
}

// 广告闪光效果
.pic-item {
  position: relative;
  overflow: hidden;
  display: block;
}

.pic-item:hover {
  transform: translateY(-2px);
  transition: 0.5s ease;
}

.pic-item:after {
  position: absolute;
  left: -100%;
  /*改变left的值，让其相对box影藏*/
  top: 0;
  width: 30%;
  height: 100%;
  content: "";
  /* Safari 5.1 - 6.0 */
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* Opera 11.1 - 12.0 */
  background: -o-linear-gradient(left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* Firefox 3.6 - 15 */
  background: -moz-linear-gradient(left,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  /* 标准的语法 */
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(255, 255, 255, 0) 100%);
  transform: skewX(-45deg);
}

.pic-item:hover:after {
  left: 150%;
  transition: 1s ease;
}
</style>

<style lang="scss">
.el-dialog.dialogTabVisible {
  .el-dialog__header {
    padding: 10px !important;
  }

  .el-dialog__body {
    padding: 20px !important;

    .el-radio__label {
      display: none;
    }

    .el-table td {
      padding: 10px 0;
    }
  }

  .el-button {
    // color: #ff2828;
    // border-color: #ff2828;
    // background: transparent;

    &:hover {
      background: #ff2828;
      color: #fff;
    }

    &:focus {
      color: #ff3636;
      border-color: #ff3636;
      background-color: transparent;
    }
  }
}

// .onlineDialogVisible {
//   .item {
//     height: 140px;
//     padding: 20px 10px;
//     text-align: center;
//     cursor: pointer;
//     @include font_col6();
//     @include tag_item_bg_col();
//     box-sizing: border-box;

//     h3 {
//       font-size: 16px;
//       margin-bottom: 10px;
//       // &::before {
//       //   content: " ";
//       //   width: 60px;
//       //   height: 2px;
//       //   background: #666;
//       //   position: absolute;
//       //   top: 26px;
//       //   left: 40px;
//       // }
//     }

//     p {
//       line-height: 24px;
//       text-align: left;
//     }

//     &:hover {
//       color: #ff2828;
//       // @include font_col3();
//       border: 1px solid #ff2828;
//     }
//   }
// }

.progressBarDialog {
  border-radius: 8px;

  .el-dialog__header {
    padding: 0;
    border: none;
  }

  .el-dialog__body {
    padding: 20px;
  }

  .ctx {
    .gameName {
      margin-bottom: 10px;
      font-size: 18px;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 14px;
        color: #333;
      }
    }

    .img-box {
      position: relative;
      margin-bottom: 20px;

      .gameImg {
        width: 100%;
        height: 100%;
        display: block;
      }

      .content {
        height: 100%;
        padding: 0 30px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);

        .title {
          font-size: 24px;
        }

        p {
          line-height: 26px;
          color: #fff;
          font-size: 18px;

          span {
            color: red;
            font-weight: bold;
          }
        }
      }

      // &::after {
      //   content: " ";
      //   width: 100%;
      //   height: 100%;
      //   background: rgba(0, 0, 0, 0.5);
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }
    }

    .el-progress__text {
      margin-left: 10px;
    }

    p {
      margin-top: 10px;
      line-height: 20px;
    }
  }
}

.gameNameDialog {
  .el-dialog__body {
    .el-input {
      width: 230px;
    }
  }

  .el-button--default:focus,
  .el-button--default:hover {
    color: #ff3636;
    border-color: #ff3636;
    background-color: transparent;
  }

  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #ff3636;
  }
}

.archive-dialog {
  .el-dialog__body {
    padding: 0 !important;
  }
}
</style>
