<template>
  <div class="view-archive-wrap">
    <el-dialog
      title="查看存档介绍"
      :visible.sync="dialogVisible"
      width="900px"
      v-if="archiveData"
      custom-class="view-archive-wrap"
    >
      <p>{{ archiveData.ArchiveName }}</p>
      <div v-if="archiveData.PerfectArchiveImgDtos.length">
        <el-carousel indicator-position="none" :autoplay="false" height="495px">
          <el-carousel-item
            v-for="(item, index) in archiveData.PerfectArchiveImgDtos"
            :key="index"
          >
            <img :src="item.ImgUrl" alt="" width="100%" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="no-img" v-else>暂无存档介绍图片</div>
      <div>{{ archiveData.PerfectArchiveImgs }}</div>
      <p>{{ archiveData.Describe }}</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["archiveData"],
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss">
.view-archive-wrap {
  .el-dialog__body {
    padding: 10px !important;
    p {
      line-height: 32px;
    }
    .no-img {
      padding: 50px 0;
      text-align: center;
      @include item_bg_col();
    }
  }
}
</style>